import React from 'react';

import { classes } from 'helpers';

import { TSizes } from 'types/product.types';

import styles from './size-select-item.module.scss';

interface SizeSelectItemProps {
  size: TSizes;
  isSelected: boolean;
  isAvailable: boolean;
  onClick: (e: TSizes) => void;
}

export const SizeSelectItem: React.FC<SizeSelectItemProps> = ({
  size,
  isAvailable,
  isSelected,
  onClick,
}) => {
  const handleClick = () => {
    onClick(size);
  };

  return (
    <div
      key={size}
      className={classes(
        styles.sizeBlock,
        !isAvailable && styles.sizeNotAvailable,
        isSelected && styles.sizeSelected,
      )}
      onClick={handleClick}
    >
      <p>{size}</p>
    </div>
  );
};
