import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import { IMAGES } from 'constants/images';

import styles from './title.module.scss';
import container from 'styles/container.module.scss';

const Title: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={container.container}>
      <div className={styles.wrapper}>
        <div className={styles.section}>
          <h1 className={styles.title}>{t('main.title.text')}</h1>
          <Paragraph outline='grayOutline' type='large' className={styles.subtitle}>
            \\ {t('main.title.subtitle.pt1')} <br />
            {t('main.title.subtitle.pt2')}
          </Paragraph>
        </div>
        <div className={styles.section}>
          <img className={styles.image} src={IMAGES.authorTitle} />
        </div>
      </div>
    </div>
  );
};

export default Title;
