import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import { Layout } from './components/layout/layout';
import { Router } from './components/router/router';

import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

export const App: React.FC = () => {
  return (
    <Suspense fallback='loading...'>
      <div className='App'>
        <ToastContainer />
        <Layout>
          <Router />
        </Layout>
      </div>
    </Suspense>
  );
};
