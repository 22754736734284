/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ILiqpayData, liqpayData } from 'helpers/liqpay';
import { notify } from 'helpers/notifications';
import { parseOrder } from 'helpers/parser';
import { orderDataScheme } from 'helpers/validation';

import { CART, PAYMENT_STATUS } from 'constants/routes';

import { IOrderData } from 'types/order.types';

import container from 'styles/container.module.scss';
import 'swiper/css';

import { DeliveryForm } from './delivery-form/delivery-form';
import { Payment } from './payment-form/payment/payment';
import { PaymentForm } from './payment-form/payment-form';
import { PersonalForm } from './personal-form/personal-form';
import { Progress } from './progress/progress';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { clearCart } from 'redux/cart.slice';
import { useSaveOrderMutation } from 'redux/products.slice';
import type SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Order: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [payment, setPayment] = useState<ILiqpayData>({} as ILiqpayData);

  const { cartItems } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const [getOrderResponse, { data, isLoading, error }] = useSaveOrderMutation();

  const methods = useForm<IOrderData>({
    defaultValues: {
      paymentMethod: 'card',
      isHome: true,
    },
    resolver: yupResolver(orderDataScheme),
    mode: 'onBlur',
  });

  const paymentRef = useRef<HTMLFormElement>(null);
  const swiperRef = useRef<SwiperCore>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const paymentMethod = methods.watch('paymentMethod');

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
  }, []);

  const components = [
    <PersonalForm nextStep={handleNext} key={0} />,
    <DeliveryForm nextStep={handleNext} prevStep={handlePrev} key={1} />,
    <PaymentForm prevStep={handlePrev} key={2} />,
  ];

  const handleStep = (currStep: number) => {
    setStep(currStep);
  };

  const onSubmit = (formsData: IOrderData) => {
    getOrderResponse(parseOrder(formsData, cartItems));
  };

  useEffect(() => {
    if (error) {
      notify(t('notifications.quantity-changed'), 'error');
      navigate(`/${CART}`);
    }
  }, [error]);

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  useEffect(() => {
    if (!data || isLoading) return;

    if (paymentMethod === 'card') {
      const reqData = liqpayData(data.order_id, data.total_price);
      handleClearCart();
      setPayment(reqData);
    } else {
      handleClearCart();
      navigate(`/${PAYMENT_STATUS}`);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(payment).length) {
      paymentRef.current?.submit();
    }
  }, [payment]);

  return (
    <div className={container.container}>
      <Progress step={step} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Swiper
            slidesPerView={1}
            allowTouchMove={false}
            onSlideChange={(swiper) => handleStep(swiper.activeIndex)}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {components.map((component, index) => (
              <SwiperSlide key={index}>{component}</SwiperSlide>
            ))}
          </Swiper>
        </form>
      </FormProvider>
      <Payment forwardRef={paymentRef} data={payment?.data} signature={payment?.signature} />
    </div>
  );
};
