import abstractBg from 'assets/images/abstract-bg.png';
import authorTitle from 'assets/images/author-title.png';
import author1 from 'assets/images/author1.png';
import author2 from 'assets/images/author2.png';
import author3 from 'assets/images/author3.png';
import author4 from 'assets/images/author4.png';
import author5 from 'assets/images/author5.png';
import author6 from 'assets/images/author6.png';
import author7 from 'assets/images/author7.png';
import author8 from 'assets/images/author8.png';
import bgIndustrial from 'assets/images/bg-industrial.png';
import book from 'assets/images/book.png';
import book1 from 'assets/images/book1.png';
import book2 from 'assets/images/book2.png';
import book3 from 'assets/images/book3.png';
import book4 from 'assets/images/book4.png';
import en from 'assets/images/en.png';
import logo from 'assets/images/logo.png';
import monobankQR from 'assets/images/monobank-qr.png';
import patch from 'assets/images/patch.png';
import shirt from 'assets/images/shirt.png';
import sizeChart from 'assets/images/size-chart.svg';
import ua from 'assets/images/ua.png';

const IMAGES_HASHMAP = {
  en,
  ua,
  logo,
  authorTitle,
  author1,
  author2,
  author3,
  author4,
  author5,
  author6,
  author7,
  author8,
  monobankQR,
  bgIndustrial,
  book1,
  book2,
  book3,
  book4,
  abstractBg,
  book,
  shirt,
  patch,
  sizeChart,
};

export type ImageName = keyof typeof IMAGES_HASHMAP;

export const IMAGES: Record<ImageName, string> = IMAGES_HASHMAP;
