import encBase64 from 'crypto-js/enc-base64';
import sha1 from 'crypto-js/sha1';

export const toBase64 = (str: string): string => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const toSha1 = (str: string): string => {
  const hash = sha1(str);
  return encBase64.stringify(hash);
};
