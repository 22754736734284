import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from 'ui';

import styles from './size-modal.module.scss';

export const SizeModal: React.FC = () => {
  const { t } = useTranslation();

  const sizeChart = [
    ['', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
    [t('product.modal.width'), '45', '45', '48', '52', '54', '56'],
    [t('product.modal.height'), '56', '58', '60', '62', '63', '65'],
  ];

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        {sizeChart.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, index) =>
              rowIndex === 0 ? <th key={index}>{cell}</th> : <td key={index}>{cell}</td>,
            )}
          </tr>
        ))}
      </table>
      <Image name='sizeChart' className={styles.image} />
    </div>
  );
};
