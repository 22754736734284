import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import styles from './author-video.module.scss';

const AuthorVideo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.videoWrapper}>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/dssGmlmyRkM'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        ></iframe>
      </div>
      <Paragraph type='large'>{t('author.video-title')}</Paragraph>
      <Paragraph type='large' color='gray' weight='wregular'>
        06.05.2023
      </Paragraph>
    </div>
  );
};

export default AuthorVideo;
