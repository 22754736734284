import React from 'react';

import { Footer, Header } from 'components';

import styles from './layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.markup}>
      <Header />
      <main>{children}</main>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};
