import React from 'react';

import { Image } from 'ui';

import { ImageName } from 'constants/images';

import styles from '../book-review.module.scss';

const images: ImageName[] = ['book1', 'book2', 'book3', 'book4'];

const BookImages: React.FC = () => {
  return (
    <div className={styles.gridFix}>
      <div className={styles.imageWrapper}>
        {images.map((image, index) => (
          <Image key={index} className={styles.image} name={image} />
        ))}
        <Image className={styles.absoluteLogo} name='logo' />
      </div>
    </div>
  );
};

export default BookImages;
