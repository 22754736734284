import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Cart } from './components/cart/cart';
import { DrawerNavigation } from './components/drawer-navigation/drawer-navigation';
import { Navigation } from './components/navigation/navigation';

import { Image } from 'ui';

import { classes } from 'helpers';

import { MAIN } from 'constants/routes';

import styles from './header.module.scss';
import container from 'styles/container.module.scss';

type Languages = 'ua' | 'en';

const languages: Languages[] = ['ua', 'en'];

export const Header: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const navigate = useNavigate();

  const logoNavigate = () => {
    navigate(MAIN);
  };

  return (
    <header className={styles.border}>
      <div className={container.container}>
        <div className={styles.wrapper}>
          <div className={styles.drawer}>
            <DrawerNavigation />
          </div>
          <Image name='logo' className={styles.logo} onClick={logoNavigate} pointer />
          <div className={styles.navigation}>
            <Navigation />
          </div>
          <div className={styles.actions}>
            <div className={styles.languages}>
              {languages.map((item) => (
                <Image
                  key={item}
                  name={item}
                  pointer
                  className={classes(styles.flag, i18n.language !== item && styles.opacity)}
                  onClick={() => changeLanguage(item)}
                />
              ))}
            </div>
            <Cart />
          </div>
        </div>
      </div>
    </header>
  );
};
