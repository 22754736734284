import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProductCard } from 'components/product-card/product-card';

import { Paragraph } from 'ui';
import { Loader } from 'ui/loader/loader';

import styles from './products.module.scss';
import container from 'styles/container.module.scss';

import { useGetAllProductsQuery } from 'redux/products.slice';

export const Products: React.FC = () => {
  const { t } = useTranslation();
  const { data, error, isLoading } = useGetAllProductsQuery();

  const isAvailable = !isLoading && data && !error;

  return isAvailable ? (
    <div className={container.container}>
      <Paragraph type='xLarge'>{t('products.title')}</Paragraph>
      <div className={styles.wrapper}>
        {data.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.fullscreenContainer}>
      <Loader />
    </div>
  );
};
