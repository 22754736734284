import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Image, Paragraph } from 'ui';

import { ImageName } from 'constants/images';
import { AUTHOR } from 'constants/routes';

import styles from './about-author.module.scss';
import container from 'styles/container.module.scss';

const images: ImageName[] = ['author1', 'author2', 'author3'];

const AboutAuthor: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={container.container}>
      <div className={styles.wrapper}>
        <div className={styles.imageSection}>
          <div className={styles.imageWrapper}>
            {images.map((image, index) => (
              <Image key={index} className={styles.image} name={image} />
            ))}
          </div>
        </div>
        <div className={styles.textSection}>
          <Paragraph type='xLarge' outline='whiteOutline'>
            {t('main.about-author.title')}
          </Paragraph>
          <Paragraph type='text'>{t('main.about-author.text')}</Paragraph>
          <Link to={`/${AUTHOR}`} className={styles.link}>
            <Paragraph type='medium' color='gray'>
              {t('main.about-author.link')}
            </Paragraph>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutAuthor;
