import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import styles from './author.module.scss';
import container from 'styles/container.module.scss';

import AuthorImages from './author-images/author-images';
import AuthorQuote from './author-quote/author-quote';
import AuthorVideo from './author-video/author-video';

const Author: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={container.container}>
      <Paragraph type='xLarge' outline='whiteOutline'>
        {t('author.title')}
      </Paragraph>
      <AuthorImages />
      <Paragraph type='large'>{t('author.subtitle')}</Paragraph>
      <div className={styles.paragraphWrapper}>
        <Paragraph type='text'>
          {t('author.text.pt1')}
          <br />
          <br />
        </Paragraph>
        <Paragraph type='text'>
          {t('author.text.pt2')}
          <br />
          <br />
        </Paragraph>
        <Paragraph type='text'>{t('author.text.pt3')}</Paragraph>
      </div>
      <AuthorVideo />
      <AuthorQuote />
    </div>
  );
};

export default Author;
