import React from 'react';

import { Image } from 'ui/image/image';

import styles from './loader.module.scss';

export const Loader: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Image name='logo' className={styles.image} />
      <Image name='logo' className={styles.image} />
      <Image name='logo' className={styles.image} />
    </div>
  );
};
