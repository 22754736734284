import { HTMLAttributes, ReactNode } from 'react';
import { UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

import { classes } from 'helpers';

import { E164Number } from 'libphonenumber-js/types';
import { IOrderData } from 'types/order.types';
import { IPersonalTrainingForm } from 'types/personal-training.types';

import styles from './input.module.scss';
import 'react-phone-number-input/style.css';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  addonAfter?: ReactNode;
  value?: string | number;
  error?: string;
  name: string;
  register?: UseFormRegister<IPersonalTrainingForm> | UseFormRegister<IOrderData>;
  setValue?: UseFormSetValue<IPersonalTrainingForm> | UseFormSetValue<IOrderData>;
  trigger?: UseFormTrigger<IPersonalTrainingForm> | UseFormTrigger<IOrderData>;
  required?: boolean;
}

export const Input: React.FC<IInputProps> = ({
  label,
  addonAfter,
  value,
  error,
  name,
  register,
  required,
  setValue,
  trigger,
  ...props
}) => {
  const handleChange = (val: E164Number) => {
    setValue && setValue(name, val);
  };

  const handleBlur = () => {
    trigger && trigger(name);
  };

  return (
    <div className={styles.wrapper}>
      {label && (
        <p className={classes(styles.label, error && styles.errorLabel)}>{error || label}</p>
      )}
      <div className={classes(styles.inputWrapper, error && styles.error)}>
        {addonAfter && <div className={styles.addonAfter}>{addonAfter}</div>}
        {name === 'phone' ? (
          <PhoneInputWithCountrySelect
            defaultCountry='UA'
            className={styles.phoneInput}
            {...props}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : (
          <input
            type='text'
            className={styles.input}
            value={value}
            {...(register && register(name, { required }))}
            {...props}
          />
        )}
      </div>
    </div>
  );
};
