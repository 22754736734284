import React from 'react';

interface IPaymentProps {
  forwardRef: React.RefObject<HTMLFormElement>;
  data: string;
  signature: string;
}

export const Payment: React.FC<IPaymentProps> = ({ forwardRef, data = '', signature = '' }) => {
  return (
    <form
      method='POST'
      action='https://www.liqpay.ua/api/3/checkout'
      acceptCharset='utf-8'
      ref={forwardRef}
    >
      <input type='hidden' name='data' value={data} />
      <input type='hidden' name='signature' value={signature} />
    </form>
  );
};
