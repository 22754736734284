import { API_URL } from 'constants/api';

import { IOrderCreateRequest, IOrderCreateResponse } from 'types/order.types';
import { IPersonalTrainingRequest } from 'types/personal-training.types';
import { ICartResponse, ILinksResponse, IProduct, TSizes } from 'types/product.types';

import { ICartItem } from './cart.slice';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const sortSizes = (product: IProduct) => {
  return {
    ...product,
    sizes: product.sizes.sort((a, b) => {
      const sizeOrder: Record<TSizes, number> = {
        xs: 0,
        s: 1,
        m: 2,
        l: 3,
        xl: 4,
        '2xl': 5,
      };
      return sizeOrder[a.size] - sizeOrder[b.size];
    }),
  };
};

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL.base, mode: 'cors' }),
  endpoints: ({ query, mutation }) => ({
    getAllProducts: query<IProduct[], void>({
      query: () => API_URL.products,
      transformResponse: (response: IProduct[]) => response.map(sortSizes),
    }),
    getProductById: query<IProduct, string>({
      query: (id) => `${API_URL.products}/${id}`,
      transformResponse: (response: IProduct) => sortSizes(response),
    }),
    getPrimaryProducts: query<IProduct[], void>({
      query: () => API_URL.productsPrimary,
      transformResponse: (response: IProduct[]) => response.map(sortSizes),
    }),
    getCartProducts: mutation<ICartResponse, Omit<ICartItem, 'price'>[]>({
      query: (items) => ({
        url: API_URL.cart,
        method: 'POST',
        body: items,
      }),
    }),
    saveOrder: mutation<IOrderCreateResponse, IOrderCreateRequest>({
      query: (order) => ({
        url: API_URL.order,
        method: 'POST',
        body: order,
      }),
    }),
    addTraining: mutation<void, IPersonalTrainingRequest>({
      query: (training) => ({
        url: API_URL.trainings,
        method: 'POST',
        body: training,
      }),
    }),
    getLinks: query<ILinksResponse[], void>({
      query: () => API_URL.links,
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductByIdQuery,
  useGetPrimaryProductsQuery,
  useGetCartProductsMutation,
  useSaveOrderMutation,
  useAddTrainingMutation,
  useGetLinksQuery,
} = productsApi;
