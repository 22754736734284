import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryModal } from 'screens/modals/delivery-modal/delivery-modal';
import { GlobalModal } from 'screens/modals/modal';

import { Button, Image, Paragraph } from 'ui';

import styles from './cart-summary.module.scss';

interface ICartSummaryProps {
  count: number;
  sum: number;
  onClick: () => void;
}

export const CartSummary: React.FC<ICartSummaryProps> = ({ count, sum, onClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.info}>
      <GlobalModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <DeliveryModal />
      </GlobalModal>
      <Paragraph type='xBig' className={styles.title}>
        {t('cart.order.title')}
      </Paragraph>
      <div className={styles.block}>
        <div className={styles.item}>
          <Paragraph type='medium' color='gray' className={styles.text}>
            {t('cart.order.text.quantity.pt1')}
          </Paragraph>
          <Paragraph type='medium' className={styles.text} weight='wregular'>
            {count} {t('cart.order.text.quantity.pt2')}
          </Paragraph>
        </div>
        <div className={styles.item}>
          <Paragraph type='medium' color='gray' className={styles.text}>
            {t('cart.order.text.total.pt1')}
          </Paragraph>
          <Paragraph type='medium' className={styles.text} weight='wregular'>
            {sum} {t('cart.order.text.total.pt2')}
          </Paragraph>
        </div>
        <div className={styles.item}>
          <Paragraph type='medium' color='gray' className={styles.text}>
            {t('cart.order.text.delivery')}
          </Paragraph>
          <Image name='information' className={styles.icon} onClick={handleModal} pointer />
        </div>
      </div>
      <Button onClick={onClick}>{t('cart.order.button')}</Button>
    </div>
  );
};
