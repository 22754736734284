import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Image, Paragraph } from 'ui';

import { notify } from 'helpers/notifications';

import { PRODUCTS } from 'constants/routes';

import { ICartProduct } from 'types/product.types';

import styles from './cart-item.module.scss';

import { Incrementor } from './incrementor/incrementor';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { decrementItemQuantity, incrementItemQuantity, removeItemFromCart } from 'redux/cart.slice';

interface ICartItemProps {
  product: ICartProduct;
}

export const CartItem: React.FC<ICartItemProps> = ({ product }) => {
  const cartProduct = useAppSelector((state) => state.cart.cartItems).find(
    (item) => item.id === product.id && item.size === product.size,
  );
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const isUa = i18n.language === 'ua';

  const increment = () => {
    if (cartProduct?.quantity === product.quantity) {
      notify(t('notifications.out-of-stock'), 'error');
      return;
    }
    dispatch(incrementItemQuantity({ id: product.id, size: product.size }));
  };

  const decrement = () => {
    if (cartProduct?.quantity === 1) {
      remove();
    }
    dispatch(decrementItemQuantity({ id: product.id, size: product.size }));
  };

  const remove = () => {
    dispatch(removeItemFromCart({ id: product.id, size: product.size }));
  };

  const navToProduct = () => {
    navigate(`/${PRODUCTS}/${product.id}`);
  };

  return cartProduct ? (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <img src={product.images[0]} className={styles.image} onClick={navToProduct} />
      </div>
      <div className={styles.info}>
        <div className={styles.bigText}>
          <Paragraph type='xBig'>
            {product.price} {t('cart.text.currency')}
          </Paragraph>
          <Paragraph type='medium'>{isUa ? product.name : product.name_en}</Paragraph>
          <Paragraph type='medium'>
            {t('cart.text.size')} {product.size.toUpperCase()}
          </Paragraph>
        </div>
        <div className={styles.description}>
          <Paragraph type='text' color='gray'>
            {isUa ? product.description : product.description_en}
          </Paragraph>
        </div>
        <Incrementor quantity={cartProduct.quantity} increment={increment} decrement={decrement} />
      </div>
      <Image name='cancel' onClick={remove} pointer className={styles.remove}></Image>
    </div>
  ) : null;
};
