import React from 'react';
import { Navigate, useRoutes } from 'react-router';

import Author from 'screens/author/author';
import { Cart } from 'screens/cart/cart';
import Main from 'screens/main/main';
import { Order } from 'screens/order/order';
import { PaymentStatus } from 'screens/payment-status/payment-status';
import PersonalTraining from 'screens/personal-training/personal-training';
import { Product } from 'screens/product/product';
import { Products } from 'screens/products/products';

import {
  AUTHOR,
  CART,
  CHECKOUT,
  MAIN,
  PAYMENT_STATUS,
  PERSONNEL_TRAININGS,
  PRODUCTS,
} from 'constants/routes';

export const Router: React.FC = () => {
  const routes = useRoutes([
    { path: '/', element: <Navigate to={MAIN} /> },
    {
      path: MAIN,
      element: <Main />,
    },
    {
      path: PRODUCTS,
      element: <Products />,
    },
    {
      path: `${PRODUCTS}/:id`,
      element: <Product />,
    },
    { path: AUTHOR, element: <Author /> },
    { path: PERSONNEL_TRAININGS, element: <PersonalTraining /> },
    { path: CART, element: <Cart /> },
    { path: CHECKOUT, element: <Order /> },
    { path: PAYMENT_STATUS, element: <PaymentStatus /> },
    { path: '*', element: <div>404</div> },
  ]);

  return routes;
};
