import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import { useNavigate } from 'react-router';

import { Footer } from 'components/footer/footer';
import { Navigation } from '../../components/navigation/navigation';

import { Image } from 'ui';

import { MAIN } from 'constants/routes';

import styles from './drawer-navigation.module.scss';
import 'react-modern-drawer/dist/index.css';

export const DrawerNavigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const logoNavigate = () => {
    navigate(MAIN);
  };

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div>
      <Image name='burger' onClick={toggleDrawer} pointer />
      <Drawer open={isOpen} onClose={toggleDrawer} direction='left' className={styles.drawer}>
        <div className={styles.header}>
          <Image name='logo' width={22} height={24} onClick={logoNavigate} />
          <Image name='cancel' width={14} height={14} onClick={toggleDrawer} pointer />
        </div>
        <Navigation closeDrawer={toggleDrawer} />
        <Footer />
      </Drawer>
    </div>
  );
};
