export const API_URL = {
  base: 'https://api.balls-of-steel.com.ua/api/',
  products: 'products',
  productsPrimary: 'products-primary/',
  cart: 'orders/basket/',
  donations: 'donate',
  order: 'orders/',
  trainings: 'trainings/',
  liqpayCallback: 'liqpay-callback/',
  links: 'social-network/',
};

export const NP_URL = {
  base: 'https://api.novaposhta.ua/v2.0/json/',
  cities: 'Address/searchSettlements/',
  warehouses: 'Address/getWarehouses',
};
