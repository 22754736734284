import React from 'react';

import { classes } from 'helpers';

import styles from './button.module.scss';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  addon?: React.ReactNode;
  variant?: 'bordered' | 'filled';
  type?: 'button' | 'submit' | 'reset';
}

export const Button: React.FC<ButtonProps> = ({
  addon,
  children,
  className,
  variant = 'filled',
  type = 'button',
  ...rest
}) => {
  return (
    <button className={classes(styles.button, styles[variant], className)} type={type} {...rest}>
      {children}
      {addon && <div className={styles.addon}>{addon}</div>}
    </button>
  );
};
