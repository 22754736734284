import { FocusEvent, HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { Input } from 'ui/input/input';

import { IOrderData } from 'types/order.types';

import styles from './select.module.scss';

import { SelectItem } from './select-item/select-item';
import { useCloseOnClickOutside } from 'hooks/closeOnClick';

export interface ISelectValue {
  name: string;
  ref: string;
}

export interface ISelectOption {
  value: ISelectValue;
  label: string;
}

interface ISelectProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  addonAfter?: ReactNode;
  inputValue: string | number;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  error?: string;
  name: string;
  required?: boolean;
  options?: ISelectOption[];
  setValue: UseFormSetValue<IOrderData>;
  trigger: UseFormTrigger<IOrderData>;
}

export const Select: React.FC<ISelectProps> = ({
  label,
  addonAfter,
  inputValue,
  setInputValue,
  error,
  name,
  options,
  setValue,
  trigger,
  ...props
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleHideDropDown = () => {
    setShowDropDown(false);
  };

  useCloseOnClickOutside(selectRef, handleHideDropDown);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSelect = (value: ISelectValue) => {
    setValue(name, value);
    setInputValue(value.name);
    setShowDropDown(false);
    trigger(name);
  };

  const handleShowDropDown = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
    setShowDropDown(true);
  };

  return (
    <div className={styles.wrapper} ref={selectRef}>
      <Input
        value={inputValue}
        onChange={handleInput}
        onFocus={handleShowDropDown}
        label={label}
        name={name}
        placeholder={props.placeholder}
        addonAfter={addonAfter}
        error={error}
      />
      {showDropDown && options && options.length > 0 && (
        <div className={styles.options}>
          {options.map((option) => (
            <SelectItem option={option} handleSelect={handleSelect} key={option.label} />
          ))}
        </div>
      )}
    </div>
  );
};
