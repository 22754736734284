import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image, Paragraph } from 'ui';

import styles from './author-quote.module.scss';

const AuthorQuote: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.textSection}>
        <Paragraph type='medium' color='white' weight='wmedium' className={styles.text}>
          {t('author.quote.text.pt1')}
          <br /> {t('author.quote.text.pt2')}
        </Paragraph>
        <Paragraph type='medium' color='white' weight='wregular' className={styles.author}>
          {t('author.quote.author-name')}
        </Paragraph>
      </div>
      <div className={styles.authorImageWrapper}>
        <Image name='author8' className={styles.authorImage} />
      </div>
    </div>
  );
};

export default AuthorQuote;
