import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import styles from './book-review.module.scss';
import container from 'styles/container.module.scss';

import BookImages from './book-images/book-images';

const BookReview: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={container.container}>
      <div className={styles.wrapper}>
        <div className={styles.textSection}>
          <Paragraph type='xLarge'>{t('main.book-review.title')}</Paragraph>
          <Paragraph type='text'>{t('main.book-review.text.pt1')}</Paragraph>
          <Paragraph type='text'>{t('main.book-review.text.pt2')}</Paragraph>
          <Paragraph type='text'>{t('main.book-review.text.pt3')}</Paragraph>
        </div>
        <div className={styles.imageSection}>
          <BookImages />
        </div>
      </div>
    </div>
  );
};

export default BookReview;
