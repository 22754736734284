import React from 'react';

import { classes } from 'helpers';

import styles from './paragraph.module.scss';

type TFontType = 'text' | 'medium' | 'xBig' | 'large' | 'mLarge' | 'xLarge';

interface ParagraphProps extends React.HTMLProps<HTMLParagraphElement> {
  type?: TFontType;
  color?: 'white' | 'gray' | 'black';
  weight?: 'wregular' | 'wmedium' | 'wbold';
  outline?: 'whiteOutline' | 'grayOutline';
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  type,
  color = 'white',
  weight,
  outline,
  ...rest
}) => {
  return (
    <p
      className={classes(
        styles[color],
        type && styles[type],
        weight && styles[weight],
        outline && styles[outline],
        className,
      )}
      {...rest}
    >
      {children}
    </p>
  );
};
