import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Image, Paragraph } from 'ui';

import { classes } from 'helpers';

import { PRODUCTS } from 'constants/routes';

import { IProduct } from 'types/product.types';

import styles from './product-card.module.scss';

import { Sizes } from './sizes/sizes';

interface IProductCardProps {
  product: IProduct;
}

export const ProductCard: React.FC<IProductCardProps> = ({ product }) => {
  const { t, i18n } = useTranslation();

  const isAvailableSizes = product.sizes.some((size) => size.quantity > 0);

  const isAvailable = product.is_active && product.sizes.length > 0 && isAvailableSizes;

  const isUa = i18n.language === 'ua';

  return (
    <Link
      className={classes(styles.wrapper, !isAvailable && styles.notAvailable)}
      to={`/${PRODUCTS}/${product.id}`}
    >
      {product.is_new && (
        <div className={styles.badge}>
          <Paragraph type='medium'>{t('products.badges.new')}</Paragraph>
        </div>
      )}

      {!isAvailable && (
        <div className={classes(styles.badge, styles.blackBadge)}>
          <Paragraph type='medium'>{t('products.badges.out-of-stock')}</Paragraph>
        </div>
      )}

      <div className={styles.imageWrapper}>
        <Image name='cartWhite' className={styles.cart} />
        <img src={product.images[0].image} className={styles.image} />
        <Sizes sizes={product.sizes} />
      </div>

      <div className={styles.info}>
        <Paragraph type='text'>{isUa ? product.name : product.name_en}</Paragraph>
        <Paragraph type='text'>
          {product.price} {t('product.curency')}
        </Paragraph>
      </div>
    </Link>
  );
};
