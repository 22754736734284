import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';
import { Loader } from 'ui/loader/loader';

import { classes } from 'helpers/styles';

import styles from './support-project.module.scss';
import container from 'styles/container.module.scss';

import { CopyToClipboard } from './copy-to-clipboard/copy-to-clipboard';
import { useGetDonationsQuery } from 'redux/dontaion.slice';

const SupportProject: React.FC = () => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useGetDonationsQuery();

  const isAvailable = !isLoading && data && !error && data[0];

  const openMonobank = () => {
    window.open(data && data[0].link, '_blank');
  };

  return isAvailable ? (
    <div className={styles.container}>
      <div className={classes(container.container, styles.wrapper)}>
        <div className={styles.textSection}>
          <Paragraph type='mLarge' className={styles.title}>
            {t('main.support-project.title')}
          </Paragraph>
          <CopyToClipboard title={t('main.support-project.monobank')} text={data[0].monobank} />
          <CopyToClipboard title='PayPal' text={data[0].paypal} />
        </div>
        <div className={styles.imageSection}>
          <img src={data[0].image} className={styles.image} onClick={openMonobank} />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );
};

export default SupportProject;
