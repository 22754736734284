import { fullNameRegExp, phoneRegExp } from 'constants/regex';

import * as yup from 'yup';

const trainingType = yup
  .string()
  .oneOf(['individual', 'group'])
  .required('Training type is required');
const fullName = yup.string().matches(fullNameRegExp, 'Full name is required').required();
const phone = yup.string().matches(phoneRegExp, 'Phone number is not valid').required();
const weaponSerialNumber = yup
  .string()
  .min(4, 'Serial number must be at least 4 characters')
  .required();
const weaponNumber = yup
  .string()
  .min(15, 'Weapon number must be at least 15 characters')
  .required();
const email = yup.string().email('Email is not valid').required();

const city = yup.string().required();
const paymentMethod = yup.string().oneOf(['cash', 'card']).required();

const citySchema = yup.object({
  name: city,
  ref: yup.string().required('City ref is required.'),
});

export const personalTrainingScheme = yup
  .object({
    trainingType,
    fullName,
    phone,
    weaponSerialNumber,
    weaponNumber,
  })
  .required();

export const orderDataScheme = yup
  .object({
    fullName,
    phone,
    email,
    city: citySchema,
    warehouse: citySchema,
    paymentMethod,
    isHome: yup.boolean().required(),
  })
  .required();
