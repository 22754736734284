import { TSizes } from 'types/product.types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICartItem {
  id: string;
  size: TSizes;
  quantity: number;
  price: number;
}

const initialState = {
  cartItems: [] as ICartItem[],
  totalPrice: 0,
  totalQuantity: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action: PayloadAction<{ id: string; size: TSizes; price: number }>) {
      const { id, size } = action.payload;

      const item = state.cartItems.find((item) => item.id === id && item.size === size);

      if (item) {
        return;
      }

      state.cartItems.push({ ...action.payload, quantity: 1 });
    },
    removeItemFromCart(state, action: PayloadAction<{ id: string; size: TSizes }>) {
      const { id, size } = action.payload;

      const cartItemsFiltered = state.cartItems.filter(
        (item) => item.id !== id || item.size !== size,
      );

      state.cartItems = cartItemsFiltered;
    },
    incrementItemQuantity(state, action: PayloadAction<{ id: string; size: TSizes }>) {
      const { id, size } = action.payload;

      const item = state.cartItems.find((item) => item.id === id && item.size === size);

      if (item) {
        item.quantity++;
      }
    },
    setItemQuantity(state, action: PayloadAction<{ id: string; size: TSizes; quantity: number }>) {
      const { id, size, quantity } = action.payload;

      const item = state.cartItems.find((item) => item.id === id && item.size === size);

      if (item) {
        item.quantity = quantity;
      }
    },
    decrementItemQuantity(state, action: PayloadAction<{ id: string; size: TSizes }>) {
      const { id, size } = action.payload;

      const item = state.cartItems.find((item) => item.id === id && item.size === size);

      if (item) {
        item.quantity--;
      }
    },
    setTotal(state) {
      const totalPrice = state.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const totalQuantity = state.cartItems.reduce((acc, item) => acc + item.quantity, 0);

      state.totalPrice = totalPrice;
      state.totalQuantity = totalQuantity;
    },
    clearCart(state) {
      state.cartItems = [];
      state.totalPrice = 0;
    },
  },
});

export default cartSlice.reducer;

export const {
  addItemToCart,
  removeItemFromCart,
  setItemQuantity,
  incrementItemQuantity,
  decrementItemQuantity,
  setTotal,
  clearCart,
} = cartSlice.actions;
