import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Paragraph } from 'ui';

import { toKebabCase, toNormalCase } from 'helpers';

import { AUTHOR, PERSONNEL_TRAININGS, PRODUCTS } from 'constants/routes';

import styles from './navigation.module.scss';

interface INavigationProps {
  closeDrawer?: () => void;
}

export const Navigation: React.FC<INavigationProps> = ({ closeDrawer }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = `${toKebabCase(location.pathname)}`;

  return (
    <nav className={styles.navigation}>
      {[AUTHOR, PERSONNEL_TRAININGS, PRODUCTS].map((route) => (
        <Link key={route} to={route} className={styles.link}>
          <Paragraph color={pathName === `/${route}` ? 'white' : 'gray'} onClick={closeDrawer}>
            {toNormalCase(t(`header.${route}`))}
          </Paragraph>
        </Link>
      ))}
    </nav>
  );
};
