import React from 'react';
import Modal from 'react-modal';

import { modalStyles } from './modal.styles';

interface IGlobalModalProps {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  children: React.ReactNode;
}

export const GlobalModal: React.FC<IGlobalModalProps> = ({ isOpen, children, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} ariaHideApp={false} style={modalStyles}>
      {children}
    </Modal>
  );
};
