import React from 'react';

import Spacer from 'components/spacer/spacer';

import AboutAuthor from './about-author/about-author';
import BookReview from './book-review/book-review';
import { FeaturedProducts } from './featured-products/featured-products';
import SupportProject from './support-project/support-project';
import Title from './title/title';

const Main: React.FC = () => {
  return (
    <div>
      <Title />
      <Spacer icon='arrowDown' isMain />
      <FeaturedProducts />
      <SupportProject />
      <AboutAuthor />
      <Spacer icon='emblem' isMain />
      <BookReview />
    </div>
  );
};

export default Main;
