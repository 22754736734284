import { useEffect } from 'react';

export const useCloseOnClickOutside = (
  selectRef: React.RefObject<HTMLDivElement>,
  setIsOpen: (value: React.SetStateAction<boolean>) => void,
) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
};
