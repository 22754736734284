import React from 'react';

import { Image } from 'ui';

import { ImageName } from 'constants/images';

import styles from './author-images.module.scss';

const images: ImageName[] = ['author4', 'author5', 'author6', 'author7'];

const AuthorImages: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      {images.map((image, index) => (
        <Image key={index} name={image} />
      ))}
    </div>
  );
};

export default AuthorImages;
