import { toast, ToastOptions } from 'react-toastify';

import styles from 'styles/notification.module.scss';

type NotificationType = 'info' | 'success' | 'warning' | 'error';

const toastConfig: ToastOptions = {
  className: styles.toast,
  position: 'bottom-left',
  closeOnClick: true,
  draggable: false,
  autoClose: 1500,
  hideProgressBar: true,
  rtl: false,
};

export const notify = (message: string, type?: NotificationType, onClick?: () => void) => {
  const toastParams = { ...toastConfig, onClick };

  switch (type) {
    case 'info':
      toast.info(message, toastParams);
      break;
    case 'success':
      toast.success(message, toastParams);
      break;
    case 'warning':
      toast.warn(message, toastParams);
      break;
    case 'error':
      toast.error(message, toastParams);
      break;
    default:
      toast(message, toastParams);
      break;
  }
};
