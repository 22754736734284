import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Paragraph } from 'ui';
import { Input } from 'ui/input/input';

import { IOrderData } from 'types/order.types';

import styles from './personal-form.module.scss';

interface IPersonalFormProps {
  nextStep: () => void;
}

const inputs = ['fullName', 'phone', 'email'];

export const PersonalForm: React.FC<IPersonalFormProps> = ({ nextStep }) => {
  const { t } = useTranslation();

  const {
    trigger,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<IOrderData>();

  const handleContinue = async () => {
    const isValid = await trigger(inputs);
    if (isValid) {
      nextStep();
    }
  };

  return (
    <div>
      <Paragraph type='xLarge'>1. {t('order.steps.personalData')}</Paragraph>
      <div className={styles.wrapper}>
        {inputs.map((input) => (
          <Input
            key={input}
            label={t(`order.personal-data.inputs.${input}.label`)}
            placeholder={t(`order.personal-data.inputs.${input}.placeholder`)}
            register={register}
            name={input}
            error={errors[input] && t(`order.personal-data.inputs.${input}.error`)}
            {...(input === 'phone' && { setValue, trigger })}
            required
          />
        ))}
        <Button onClick={handleContinue}>{t('order.buttons.next')}</Button>
      </div>
    </div>
  );
};
