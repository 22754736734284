import React from 'react';

import styles from './incrementor.module.scss';

interface IIncrementorProps {
  quantity: number;
  increment: () => void;
  decrement: () => void;
}

export const Incrementor: React.FC<IIncrementorProps> = ({ quantity, increment, decrement }) => {
  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={decrement}>
        -
      </button>
      <div className={styles.textWrap}>
        <p className={styles.text}>{quantity}</p>
      </div>
      <button className={styles.button} onClick={increment}>
        +
      </button>
    </div>
  );
};
