import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Paragraph } from 'ui';

import { PRODUCTS } from 'constants/routes';

import styles from './cart-empty.module.scss';

export const CartEmpty: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToProducts = () => {
    navigate(`/${PRODUCTS}`);
  };

  return (
    <div className={styles.empty}>
      <Paragraph type='xBig'>{t('cart.empty.title')}</Paragraph>
      <Button onClick={navigateToProducts}>{t('cart.empty.text')}</Button>
    </div>
  );
};
