import { HTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { classes } from 'helpers/styles';

import styles from './radio.module.scss';

interface IRadioButtonProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  value: string;
  checked?: boolean;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

export const Radio: React.FC<IRadioButtonProps> = ({
  label,
  value,
  register,
  name,
  required,
  checked,
  error,
  disabled,
  ...props
}) => {
  return (
    <label className={classes(styles.label, disabled && styles.disabled)}>
      <input
        className={classes(styles.circle, error && styles.error)}
        type='radio'
        id={value}
        checked={checked}
        value={value}
        {...(register && register(name, { required: required }))}
        {...props}
      />
      {label}
    </label>
  );
};
