/* eslint-disable camelcase */
import { API_URL } from 'constants/api';
import { PAYMENT_STATUS } from 'constants/routes';

import { toBase64, toSha1 } from './encoder';

export interface ILiqpayData {
  data: string;
  signature: string;
}

export const liqpayData = (orderId: string, amount: number): ILiqpayData => {
  const data = JSON.stringify({
    public_key: process.env.REACT_APP_LP_PUBLIC_KEY,
    action: 'pay',
    amount,
    currency: 'UAH',
    description: `Оплата замовлення №${orderId}`,
    order_id: orderId,
    language: 'uk',
    result_url: `${process.env.REACT_APP_BASE_URL}/${PAYMENT_STATUS}`,
    server_url: `${API_URL.base}${API_URL.liqpayCallback}`,
    sandbox: 1,
  });

  const dataToBase64 = toBase64(data);
  const signature = toSha1(
    `${process.env.REACT_APP_LP_PRIVATE_KEY}${dataToBase64}${process.env.REACT_APP_LP_PRIVATE_KEY}`,
  );

  return { data: dataToBase64, signature: signature };
};
