import { NP_URL } from 'constants/api';

import { IOrderCities, IOrderWarehouses } from 'types/order.types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IWarehouseRequest {
  city: string;
  warehouse: string;
}

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: NP_URL.base, mode: 'cors' }),
  endpoints: ({ mutation }) => ({
    getCities: mutation<IOrderCities, string>({
      query: (city) => ({
        url: NP_URL.cities,
        method: 'POST',
        body: {
          apiKey: process.env.REACT_APP_NOVA_POSHTA_PUBLIC_KEY,
          modelName: 'Address',
          calledMethod: 'searchSettlements',
          methodProperties: {
            CityName: city,
          },
        },
      }),
    }),
    getWarehouses: mutation<IOrderWarehouses, IWarehouseRequest>({
      query: (search) => ({
        url: NP_URL.cities,
        method: 'POST',
        body: {
          apiKey: process.env.REACT_APP_NOVA_POSHTA_PUBLIC_KEY,
          modelName: 'Address',
          calledMethod: 'getWarehouses',
          methodProperties: {
            CityRef: search.city,
            Limit: 10,
            FindByString: search.warehouse,
          },
        },
      }),
    }),
  }),
});

export const { useGetCitiesMutation, useGetWarehousesMutation } = orderApi;
