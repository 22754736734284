import { useEffect, useState } from 'react';

import { ISelectOption } from 'ui/select/select';

import { useDebounce } from 'hooks/debounce';
import { useGetWarehousesMutation } from 'redux/order.slice';

export const useWarehouseState = (city: string) => {
  const [getWarehousesResponse, { data }] = useGetWarehousesMutation();
  const [warehousesOptions, setWarehousesOptions] = useState<ISelectOption[]>();
  const [warehousesInputValue, setWarehousesInputValue] = useState<string>('');
  const warehouse = useDebounce(warehousesInputValue, 500);

  useEffect(() => {
    fetchWarehouses();
  }, [warehouse, city]);

  useEffect(() => {
    setWarehousesInputValue('');
    setWarehousesOptions([]);
  }, [city]);

  const fetchWarehouses = () => {
    getWarehousesResponse({ city, warehouse });
  };

  useEffect(() => {
    if (!data?.success || data?.data.length === 0) {
      return;
    }

    const warehousesOptions = data?.data.map((warehouse) => ({
      value: { name: warehouse.Description, ref: warehouse.Number },
      label: warehouse.Description,
    }));

    setWarehousesOptions(warehousesOptions);
  }, [data]);

  return {
    warehousesInputValue,
    setWarehousesInputValue,
    warehousesOptions,
  };
};
