import React from 'react';

import { Paragraph } from 'ui/paragraph/paragraph';

import { ISize } from 'types/product.types';

import styles from './sizes.module.scss';

interface ISizesProps {
  sizes: ISize[];
}

export const Sizes: React.FC<ISizesProps> = ({ sizes }) => {
  return (
    <div className={styles.wrapper}>
      {sizes.map((size) => (
        <div key={size.size} className={styles.divider}>
          <Paragraph
            type='text'
            color={size.quantity === 0 ? 'gray' : 'white'}
            className={styles.text}
          >
            {size.size && size.size.toUpperCase()}
          </Paragraph>
        </div>
      ))}
    </div>
  );
};
