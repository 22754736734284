import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ProductCard } from 'components/product-card/product-card';

import { Button, Paragraph } from 'ui';
import { Loader } from 'ui/loader/loader';

import { PRODUCTS } from 'constants/routes';

import styles from './featured-products.module.scss';
import container from 'styles/container.module.scss';

import { useGetPrimaryProductsQuery } from 'redux/products.slice';

export const FeaturedProducts: React.FC = () => {
  const { data, isLoading, error } = useGetPrimaryProductsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isAvailable = !isLoading && data && !error;

  const navToProducts = () => {
    navigate(`/${PRODUCTS}`);
  };

  return isAvailable ? (
    <div className={container.container}>
      <Paragraph type='xLarge'>{t('main.featured-products.title')}</Paragraph>
      <div className={styles.wrapper}>
        {data.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <Button onClick={navToProducts}>{t('main.featured-products.button')}</Button>
    </div>
  ) : (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );
};
