import arrowDown from 'assets/icons/arrow-down.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import burger from 'assets/icons/burger.svg';
import cancel from 'assets/icons/cancel.svg';
import cart from 'assets/icons/cart.svg';
import cartWhite from 'assets/icons/cart-white.svg';
import emblem from 'assets/icons/emblem.svg';
import headphones from 'assets/icons/headphones.svg';
import information from 'assets/icons/information.svg';

const ICONS_HASHMAP = {
  arrowRight,
  burger,
  cancel,
  cart,
  arrowDown,
  emblem,
  headphones,
  cartWhite,
  information,
};

export type IconName = keyof typeof ICONS_HASHMAP;

export const ICONS: Record<IconName, string> = ICONS_HASHMAP;
