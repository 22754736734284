import { API_URL } from 'constants/api';

import { IDonation } from 'types/donations.types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const donationApi = createApi({
  reducerPath: 'donationApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL.base, mode: 'cors' }),
  endpoints: ({ query }) => ({
    getDonations: query<IDonation[], void>({
      query: () => API_URL.donations,
    }),
  }),
});

export const { useGetDonationsQuery } = donationApi;
