import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Image, Paragraph } from 'ui';
import { Radio } from 'ui/radio/radio';
import { Select } from 'ui/select/select';

import { notify } from 'helpers/notifications';
import { parseOrder } from 'helpers/parser';

import { IOrderData } from 'types/order.types';

import styles from './delivery-form.module.scss';

import { useCityState } from './hooks/cityState';
import { useWarehouseState } from './hooks/warehouseState';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { clearCart } from 'redux/cart.slice';
import { useGetLinksQuery, useSaveOrderMutation } from 'redux/products.slice';

interface IDeliveryFormProps {
  nextStep: () => void;
  prevStep: () => void;
}

const inputs = ['city', 'warehouse'];

export const DeliveryForm: React.FC<IDeliveryFormProps> = ({ nextStep, prevStep }) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<IOrderData>();

  const { cartItems } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const { citiesInputValue, setCitiesInputValue, citiesOptions } = useCityState();
  const { warehousesInputValue, setWarehousesInputValue, warehousesOptions } = useWarehouseState(
    watch('city.ref'),
  );
  const { data: linksData, isLoading: linksIsLoading, error: linksError } = useGetLinksQuery();
  const [saveOrderResponse] = useSaveOrderMutation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleContinue = async () => {
    const isValid = await trigger(inputs);
    if (isValid) {
      nextStep();
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('isHome', e.target.value === 'home');
  };

  const handleContact = () => {
    try {
      if (linksIsLoading || linksError || !linksData) {
        notify(t('notifications.error'), 'error');
        return;
      }
      saveOrderResponse(parseOrder(watch(), cartItems));
      dispatch(clearCart());
      navigate('/');
      window.open(linksData[0].telegram);
    } catch {
      notify(t('notifications.error'), 'error');
    }
  };

  return (
    <div>
      <Paragraph type='xLarge'>2. {t('order.steps.delivery')}</Paragraph>
      <div className={styles.wrapper}>
        <div className={styles.radioWrap}>
          {['home', 'abroad'].map((radio) => (
            <Radio
              key={radio}
              onChange={handleRadioChange}
              label={t(`order.delivery.radios.${radio}`)}
              value={radio}
              checked={watch('isHome') ? radio === 'home' : radio === 'abroad'}
              name='isLocal'
            />
          ))}
        </div>
        {watch('isHome') ? (
          <>
            {inputs.map((input) => (
              <Select
                key={input}
                label={t(`order.delivery.inputs.${input}.label`)}
                placeholder={t(`order.delivery.inputs.${input}.placeholder`)}
                inputValue={input === 'city' ? citiesInputValue : warehousesInputValue}
                setInputValue={input === 'city' ? setCitiesInputValue : setWarehousesInputValue}
                name={input}
                error={errors[input] && t(`order.delivery.inputs.${input}.error`)}
                options={input === 'city' ? citiesOptions : warehousesOptions}
                trigger={trigger}
                setValue={setValue}
                required
              />
            ))}
            <div className={styles.buttonsWrap}>
              <Button onClick={handleContinue}>{t('order.buttons.next')}</Button>
              <Button onClick={prevStep} variant='bordered'>
                {t('order.buttons.previous')}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.buttonsWrap}>
            <Button onClick={handleContact} addon={<Image name='headphones' />}>
              {t('order.buttons.manager')}
            </Button>
            <Button onClick={prevStep} variant='bordered'>
              {t('order.buttons.previous')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
