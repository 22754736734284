import { useEffect, useState } from 'react';

import { ISelectOption } from 'ui/select/select';

import { useDebounce } from 'hooks/debounce';
import { useGetCitiesMutation } from 'redux/order.slice';

export const useCityState = () => {
  const [getCitiesResponse, { data }] = useGetCitiesMutation();
  const [citiesOptions, setCitiesOptions] = useState<ISelectOption[]>();
  const [citiesInputValue, setCitiesInputValue] = useState<string>('');
  const city = useDebounce(citiesInputValue, 500);

  useEffect(() => {
    fetchCities();
  }, [city]);

  const fetchCities = () => {
    if (city) {
      getCitiesResponse(city);
    } else {
      setCitiesOptions([]);
    }
  };

  useEffect(() => {
    if (!data?.success || data?.data[0].TotalCount === 0) {
      return;
    }

    const cityOptions = data?.data[0].Addresses.map((city) => ({
      value: { name: city.Present, ref: city.DeliveryCity },
      label: city.Present,
    }));

    setCitiesOptions(cityOptions);
  }, [data]);

  return {
    citiesInputValue,
    setCitiesInputValue,
    citiesOptions,
  };
};
