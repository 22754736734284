import React from 'react';

import { Image, Paragraph } from 'ui';

import { classes } from 'helpers/styles';

import styles from './footer.module.scss';
import container from 'styles/container.module.scss';

import { useGetLinksQuery } from 'redux/products.slice';

export const Footer: React.FC = () => {
  const { data } = useGetLinksQuery();

  const isAvailable = data && data[0];

  return (
    <footer className={styles.border}>
      <div className={classes(container.container, styles.wrapper)}>
        <div className={styles.footer}>
          <a
            href={isAvailable && data[0].telegram}
            target='_blank'
            className={styles.link}
            rel='noreferrer'
          >
            <Paragraph>Telegram</Paragraph>
            <Image name='arrowRight' className={styles.image} />
          </a>
          <a
            href={isAvailable && data[0].instagram}
            className={styles.link}
            target='_blank'
            rel='noreferrer'
          >
            <Paragraph>Instagram</Paragraph>
            <Image name='arrowRight' className={styles.image} />
          </a>
        </div>
      </div>
    </footer>
  );
};
