import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Paragraph } from 'ui';

import { MAIN } from 'constants/routes';

import styles from './payment-status.module.scss';
import container from 'styles/container.module.scss';

export const PaymentStatus: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navToHome = () => {
    navigate(`/${MAIN}`);
  };
  return (
    <div className={container.container}>
      <div className={styles.wrapper}>
        <Paragraph type='xLarge' outline='whiteOutline'>
          {t('payment-success.title')}
        </Paragraph>
        <Button onClick={navToHome}> {t('payment-success.button')}</Button>
      </div>
    </div>
  );
};
