import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import styles from './delivery-modal.module.scss';

export const DeliveryModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Paragraph type='medium'>{t('cart.modal.title')}</Paragraph>
      <ul className={styles.list}>
        {['eu', 'usa', 'canada'].map((item) => (
          <li key={item}>
            <Paragraph type='text' color='gray'>
              {t(`cart.modal.text.${item}`)}
            </Paragraph>
          </li>
        ))}
      </ul>
    </div>
  );
};
