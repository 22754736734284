import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Paragraph } from 'ui';
import { Loader } from 'ui/loader/loader';

import { notify } from 'helpers/notifications';

import { CHECKOUT } from 'constants/routes';

import { ICartResponse } from 'types/product.types';

import styles from './cart.module.scss';
import container from 'styles/container.module.scss';

import { CartEmpty } from './cart-empty/cart-empty';
import { CartItem } from './cart-item/cart-item';
import { CartSummary } from './cart-summary/cart-summary';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { removeItemFromCart, setItemQuantity, setTotal } from 'redux/cart.slice';
import { useGetCartProductsMutation } from 'redux/products.slice';

export const Cart: React.FC = () => {
  const { cartItems, totalPrice, totalQuantity } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [getCartResponse, { data, isLoading, error }] = useGetCartProductsMutation();
  const isAvailable = !isLoading && data && !error;

  const navigate = useNavigate();

  const issueOrder = () => {
    awaitCheckCart();
  };

  const awaitCheckCart = async () => {
    try {
      const res = await getCartResponse(cartItems).unwrap();
      const isEnough = checkQuantities(res);

      if (isEnough) {
        navigate(`/${CHECKOUT}`);
      } else {
        notify(t('notifications.quantity-changed'), 'warning');
      }
    } catch (error) {
      notify(t('notifications.error'), 'error');
    }
  };

  const checkQuantities = (items: ICartResponse): boolean => {
    let tempCanProceed = false;

    cartItems.forEach((item) => {
      const foundItem = items.products.find(
        (product) => product.id === item.id && product.size === item.size,
      );
      if (!foundItem || item.quantity > foundItem?.quantity) {
        if (foundItem?.quantity === 0 || !foundItem?.quantity) {
          dispatch(removeItemFromCart({ id: item.id, size: item.size }));
        } else {
          dispatch(
            setItemQuantity({ id: item.id, size: item.size, quantity: foundItem?.quantity }),
          );
        }
        tempCanProceed = false;
      } else {
        tempCanProceed = true;
      }
    });
    return tempCanProceed;
  };

  useEffect(() => {
    dispatch(setTotal());
  }, [cartItems]);

  useEffect(() => {
    getCartResponse(cartItems);
  }, [cartItems.length]);

  useEffect(() => {
    if (!data) return;
    checkQuantities(data);
  }, [data]);

  if (!cartItems.length) {
    return (
      <div className={styles.fullscreenContainer}>
        <CartEmpty />
      </div>
    );
  }

  return isAvailable ? (
    <div className={container.container}>
      <div className={styles.wrapper}>
        <div className={styles.itemsWrapper}>
          <Paragraph type='xBig' className={styles.title}>
            {t('cart.title')}
          </Paragraph>
          <div className={styles.scroll}>
            {data.products.map((item, index) => (
              <CartItem key={index} product={item} />
            ))}
          </div>
        </div>
        <CartSummary count={totalQuantity} sum={totalPrice} onClick={issueOrder} />
      </div>
    </div>
  ) : (
    <div className={styles.fullscreenContainer}>
      <Loader />
    </div>
  );
};
