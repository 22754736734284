import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalModal } from 'screens/modals/modal';
import { SizeModal } from 'screens/modals/size-modal/size-modal';

import { Paragraph } from 'ui';

import { ISize, TSizes } from 'types/product.types';

import styles from './size-select.module.scss';

import { SizeSelectItem } from './size-select-item/size-select-item';

interface ISizeSelectProps {
  selectedSize: TSizes | null;
  setSelectedSize: (size: TSizes) => void;
  availableSizes: ISize[];
}

export const SizeSelect: React.FC<ISizeSelectProps> = ({
  selectedSize,
  availableSizes,
  setSelectedSize,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  return (
    <div className={styles.wrapper}>
      {isModalOpen && (
        <GlobalModal setIsOpen={handleModalOpen} isOpen={isModalOpen}>
          <SizeModal />
        </GlobalModal>
      )}
      <div className={styles.textWrap}>
        <Paragraph type='medium' color='gray' weight='wregular'>
          {t('product.size.pt1')}
        </Paragraph>
        <Paragraph
          type='medium'
          color='gray'
          weight='wregular'
          className={styles.link}
          onClick={() => handleModalOpen(true)}
        >
          {t('product.size.pt2')}
        </Paragraph>
      </div>
      <div className={styles.sizesWrap}>
        {availableSizes.map(({ size, quantity }) => (
          <SizeSelectItem
            key={size}
            size={size}
            isAvailable={quantity > 0}
            isSelected={selectedSize === size}
            onClick={setSelectedSize}
          />
        ))}
      </div>
    </div>
  );
};
