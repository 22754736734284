import React from 'react';
import { useTranslation } from 'react-i18next';

import { classes } from 'helpers';

import styles from './progress.module.scss';

interface IProgressProps {
  step?: number;
}

export const Progress: React.FC<IProgressProps> = ({ step = 0 }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {['personalData', 'delivery', 'payment'].map((item, index) => (
        <div className={styles.itemWrapper} key={index}>
          <div className={classes(styles.item, step === index && styles.active)}>
            <p>{index + 1}</p>
          </div>
          <span className={classes(styles.text, step === index && styles.textActive)}>
            {t(`order.steps.${item}`)}
          </span>
        </div>
      ))}
      <div className={styles.line} />
    </div>
  );
};
