import React from 'react';

import { Paragraph } from 'ui/paragraph/paragraph';

import styles from './select-item.module.scss';

import { ISelectOption, ISelectValue } from '../select';

interface ISelectItemProps {
  option: ISelectOption;
  handleSelect: (item: ISelectValue) => void;
}

export const SelectItem: React.FC<ISelectItemProps> = ({ option, handleSelect }) => {
  const select = () => {
    handleSelect(option.value);
  };

  return (
    <div className={styles.option} onClick={select}>
      <Paragraph type='text' weight='wregular'>
        {option.label}
      </Paragraph>
    </div>
  );
};
