/* eslint-disable camelcase */
import { IOrderData } from 'types/order.types';

import { ICartItem } from 'redux/cart.slice';

export const parseOrder = (userData: IOrderData, cartData: ICartItem[]) => {
  const orderData = {
    is_paid: false,
    order_items: cartData.map((item) => ({
      product: item.id,
      size: item.size,
      quantity: item.quantity,
    })),
    order_personal: {
      fullname: userData.fullName,
      phone_number: userData.phone,
      email: userData.email,
    },
    order_delivery: {
      country_delivery: userData.isHome ? 'ukraine' : 'other',
      city: userData.city ? userData.city.name : '-',
      number_post: userData.warehouse ? userData.warehouse.name : '-',
      payment_method: userData.paymentMethod || '-',
    },
  };

  return orderData;
};
