import React from 'react';
import ImageGallery from 'react-image-gallery';

import { TImage } from 'types/product.types';

import styles from './gallery.module.scss';
import 'react-image-gallery/styles/css/image-gallery.css';

interface IGalleryProps {
  images: TImage[];
}

export const Gallery: React.FC<IGalleryProps> = ({ images }) => {
  const isMobile = window.innerWidth <= 768;

  return isMobile ? (
    <ImageGallery
      items={images.map(({ image }) => ({
        original: image,
        thumbnail: image,
        originalClass: styles.image,
        thumbnailClass: styles.thumbnail,
      }))}
      showBullets={true}
      showPlayButton={false}
      showThumbnails={false}
      showFullscreenButton={false}
    />
  ) : (
    <ImageGallery
      items={images.map(({ image }) => ({
        original: image,
        thumbnail: image,
        originalClass: styles.image,
        thumbnailClass: styles.thumbnail,
      }))}
      showPlayButton={false}
      showNav={false}
      showFullscreenButton={false}
    />
  );
};
