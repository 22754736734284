import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Paragraph } from 'ui';
import { Radio } from 'ui/radio/radio';

import { IOrderData } from 'types/order.types';

import styles from './payment-form.module.scss';

export interface IPaymentFormProps {
  prevStep: () => void;
}

export const PaymentForm: React.FC<IPaymentFormProps> = ({ prevStep }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IOrderData>();

  const { t } = useTranslation();

  return (
    <div>
      <Paragraph type='xLarge'>3. {t('order.steps.payment')}</Paragraph>
      <div className={styles.wrapper}>
        <div className={styles.radioWrap}>
          <Paragraph type='text' color='gray'>
            {t('order.payment.sub-title')}
          </Paragraph>
          {['card', 'cash'].map((radio) => (
            <Radio
              key={radio}
              register={register}
              label={t(`order.payment.radios.${radio}`)}
              error={errors.paymentMethod?.message}
              value={radio}
              disabled={radio === 'cash'}
              name='paymentMethod'
            />
          ))}
        </div>
        <Paragraph type='medium' className={styles.info}>
          {t('order.payment.info')}
        </Paragraph>
        <Button type='submit'>{t('order.payment.button')}</Button>
        <Button onClick={prevStep} variant='bordered'>
          {t('order.buttons.previous')}
        </Button>
      </div>
    </div>
  );
};
