import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Button, Image, Paragraph } from 'ui';
import { Loader } from 'ui/loader/loader';

import { classes } from 'helpers';
import { notify } from 'helpers/notifications';

import { CART } from 'constants/routes';

import { TSizes } from 'types/product.types';

import styles from './product.module.scss';
import container from 'styles/container.module.scss';

import { Gallery } from './gallery/gallery';
import { SizeSelect } from './size-select/size-select';
import { useAppDispatch } from 'hooks/reduxHooks';
import { addItemToCart } from 'redux/cart.slice';
import { useGetProductByIdQuery } from 'redux/products.slice';

export const Product: React.FC = () => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, error, isLoading } = useGetProductByIdQuery(params.id!);

  const [selectedSize, setSelectedSize] = useState<TSizes | null>(null);
  const dispatch = useAppDispatch();

  const isUa = i18n.language === 'ua';

  const addToCart = () => {
    if (selectedSize === null || !data) {
      notify(t('notifications.select-size'), 'error');
      return false;
    }
    dispatch(
      addItemToCart({
        id: data.id,
        size: selectedSize,
        price: data.price,
      }),
    );
    notify('Додано в корзину', 'success', navigateToCart);
    return true;
  };

  const navigateToCart = () => {
    navigate(`/${CART}`);
  };

  const buy = () => {
    addToCart() && navigate(`/${CART}`);
  };

  const isAvailable = !isLoading && data && !error;

  return isAvailable ? (
    <div className={classes(container.container, styles.container)}>
      <div className={styles.wrapper}>
        <div className={styles.imgSection}>
          <Gallery images={data.images} />
        </div>
        <div className={styles.textSection}>
          <Paragraph type='xBig'>{isUa ? data.name : data.name_en}</Paragraph>
          <Paragraph type='medium' color='gray' weight='wregular' className={styles.category}>
            {isUa ? data.category : data.category_en}
          </Paragraph>
          <div className={styles.priceWrap}>
            <Paragraph type='xBig'>
              {data.price} {t('product.curency')}
            </Paragraph>
            <Paragraph type='xBig' color='gray' weight='wregular'>
              (${data.price_usd})
            </Paragraph>
          </div>
          <SizeSelect
            selectedSize={selectedSize}
            availableSizes={data.sizes}
            setSelectedSize={setSelectedSize}
          />
          <div className={styles.buttonWrap}>
            <Button onClick={buy}>{t('product.buttons.buy')}</Button>
            <Button variant='bordered' addon={<Image name='cart' width={32} />} onClick={addToCart}>
              {t('product.buttons.add-to-cart')}
            </Button>
          </div>
          <ul className={styles.list}>
            <li>
              <Paragraph type='medium' weight='wregular'>
                {t('product.text.pt1')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type='medium' weight='wregular'>
                {t('product.text.pt2')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type='medium' weight='wregular'>
                {t('product.text.pt3')}
              </Paragraph>
            </li>
          </ul>
        </div>
      </div>
      <Paragraph type='text' weight='wregular'>
        {isUa ? data.description : data.description_en}
      </Paragraph>
    </div>
  ) : (
    <div className={styles.fullscreenContainer}>
      <Loader />
    </div>
  );
};
