/* eslint-disable camelcase */
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Image, Paragraph } from 'ui';
import { Input } from 'ui/input/input';
import { Radio } from 'ui/radio/radio';

import { classes } from 'helpers';
import { notify } from 'helpers/notifications';
import { personalTrainingScheme } from 'helpers/validation';

import { IPersonalTrainingForm } from 'types/personal-training.types';

import styles from './personal-training.module.scss';
import container from 'styles/container.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAddTrainingMutation, useGetLinksQuery } from 'redux/products.slice';

const PersonalTraining: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useGetLinksQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm<IPersonalTrainingForm>({
    resolver: yupResolver(personalTrainingScheme),
    mode: 'onBlur',
  });

  const [addTraining] = useAddTrainingMutation();

  const onSubmit: SubmitHandler<IPersonalTrainingForm> = (data) => {
    try {
      addTraining({
        fullname: data.fullName,
        phone_number: data.phone,
        weapon_series: data.weaponSerialNumber,
        weapon_number: data.weaponNumber,
        type_training: data.trainingType,
      });
      notify(t('notifications.training-success'), 'success');
      openTelegram();
    } catch (error) {
      notify(t('notifications.error'), 'error');
    }
  };

  const openTelegram = () => {
    window.open(data && data[0].telegram);
    reset();
  };

  return (
    <div className={classes(container.container, styles.mb)}>
      <form className={styles.bg} onSubmit={handleSubmit(onSubmit)}>
        <Paragraph type='xLarge' outline='whiteOutline'>
          {t('personal-training.title.pt1')}
          <br />
          {t('personal-training.title.pt2')}
        </Paragraph>
        <div className={styles.section}>
          <div className={styles.radioWrap}>
            {['group', 'individual'].map((radioButton) => (
              <Radio
                key={radioButton}
                label={t(`personal-training.radios.${radioButton}`)}
                value={radioButton}
                register={register}
                name='trainingType'
                error={errors.trainingType?.message}
                required
              />
            ))}
          </div>
          <div className={styles.inputsWrap}>
            {['fullName', 'phone', 'weaponSerialNumber', 'weaponNumber'].map((input) => (
              <Input
                key={input}
                label={t(`personal-training.inputs.${input}.label`)}
                placeholder={t(`personal-training.inputs.${input}.placeholder`)}
                name={input}
                register={register}
                required
                error={errors[input] && t(`personal-training.inputs.${input}.error`)}
                {...(input === 'phone' && { setValue, trigger })}
              />
            ))}
          </div>
          <Paragraph type='medium'>
            {t('personal-training.text.pt1')}
            {watch('trainingType') === 'group' ? '1500' : '4000'} {t('personal-training.text.pt2')}
          </Paragraph>
          <Paragraph type='medium' color='gray'>
            {t('personal-training.text.pt3')}
          </Paragraph>
        </div>
        <Button addon={<Image name='headphones' />} type='submit'>
          {t('personal-training.button')}
        </Button>
      </form>
    </div>
  );
};

export default PersonalTraining;
