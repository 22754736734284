import React from 'react';

import { classes } from 'helpers';

import { IconName, ICONS } from 'constants/icons';

import styles from './spacer.module.scss';

interface SpacerProps {
  size?: string;
  direction?: 'horizontal' | 'vertical';
  icon?: IconName;
  isMain?: boolean;
}

const Spacer: React.FC<SpacerProps> = ({
  size = '16px',
  direction = 'horizontal',
  icon,
  isMain,
}) => {
  return icon ? (
    <div
      className={classes(styles[direction], isMain && styles.main)}
      style={{ width: '100%', height: size }}
    >
      <div className={styles.line}>
        <img className={styles.icon} src={ICONS[icon]} />
      </div>
    </div>
  ) : (
    <div
      className={classes(styles[direction], isMain && styles.main)}
      style={
        direction === 'horizontal'
          ? { width: '100%', height: size }
          : { height: '100%', width: size }
      }
    />
  );
};

export default Spacer;
