import React from 'react';

import { classes } from 'helpers';

import { IconName, ICONS } from 'constants/icons';
import { ImageName, IMAGES } from 'constants/images';

import styles from './image.module.scss';

interface ImageProps extends React.HTMLProps<HTMLImageElement> {
  name: IconName | ImageName;
  width?: number;
  height?: number;
  pointer?: boolean;
}

export const Image: React.FC<ImageProps> = ({
  name,
  className,
  width,
  height,
  pointer,
  ...rest
}) => {
  const imagesHash = { ...IMAGES, ...ICONS };

  return (
    <img
      src={imagesHash[name]}
      alt={name}
      className={classes(styles.image, pointer && styles.pointer, className)}
      style={{ width, height }}
      {...rest}
    />
  );
};
