import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'ui';

import { notify } from 'helpers/notifications';

import styles from './copy-to-clipboard.module.scss';

interface ICopyToClipboardProps {
  title: string;
  text: string;
}

export const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ title, text }) => {
  const { t } = useTranslation();

  const copyText = () => {
    navigator.clipboard.writeText(text);
    notify(t('notifications.copy'), 'success');
  };

  return (
    <Paragraph type='large' onClick={copyText} className={styles.copy}>
      {title}: <br /> {text}
    </Paragraph>
  );
};
