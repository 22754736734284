import { Styles } from 'react-modal';

export const modalStyles: Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    zIndex: 99999,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    minWidth: 'max-content',
    minHeight: 'max-content',
    overflow: '',
    background: '#000000',
    borderRadius: '0',
    outline: 'none',
    padding: '20px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
};
