import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from 'ui';

import { CART } from 'constants/routes';

import styles from './cart.module.scss';

import { useAppSelector } from 'hooks/reduxHooks';

export const Cart: React.FC = () => {
  const navigate = useNavigate();

  const cartItems = useAppSelector((state) => state.cart.cartItems);
  const cartAmount = cartItems.length;

  const cartNavigate = () => {
    navigate(CART);
  };

  return (
    <div className={styles.wrapper} onClick={cartNavigate}>
      <Image name='cart' className={styles.cart} pointer />
      {!!cartAmount && <div className={styles.indicator}>{cartItems.length}</div>}
    </div>
  );
};
